<template>
  <div class="datasource-container">
    <!-- OAUTH2 OAUTH2_EMBED-->
    <v-card elevation="0" class="dense" v-if="type === 'OAUTH2' || type === 'OAUTH2_EMBED' ">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>scope：</span>
            <span
              :style="{
                color: scope ? '' : '#9195a3'
              }"
            >
              {{ scope || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>App ID：</span>
            <span
              :style="{
                color: client_id ? '' : '#9195a3'
              }"
            >
              {{ client_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>App Secret：</span>
            <span
              :style="{
                color: client_secret ? '' : '#9195a3'
              }"
            >
              {{ client_secret || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>授权URL：</span>
            <span
              :style="{
                color: authorization_base_url ? '' : '#9195a3'
              }"
            >
              {{ authorization_base_url || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>访问令牌API：</span>
            <span
              :style="{
                color: token_api_url ? '' : '#9195a3'
              }"
            >
              {{ token_api_url || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>用户信息API：</span>
            <span
              :style="{
                color: user_api_url ? '' : '#9195a3'
              }"
            >
              {{ user_api_url || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>用户ID字段：</span>
            <span
              :style="{
                color: identity_attribute ? '' : '#9195a3'
              }"
            >
              {{ identity_attribute || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- WECHAT_ENTERPRISE -->
    <v-card elevation="0" class="dense" v-if="type === 'WECHAT_ENTERPRISE'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>使用微信扫码：</span>
            <span
              :style="{
                color: wechat_qrcode ? '' : '#9195a3'
              }"
            >
              {{ wechat_qrcode ? "已开启" : "未开启" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>企业ID：</span>
            <span
              :style="{
                color: corp_id ? '' : '#9195a3'
              }"
            >
              {{ corp_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>Agent ID：</span>
            <span
              :style="{
                color: agent_id ? '' : '#9195a3'
              }"
            >
              {{ agent_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>应用的凭证密钥：</span>
            <span
              :style="{
                color: agent_secret ? '' : '#9195a3'
              }"
            >
              {{ agent_secret || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- CAS || CAS_REST_API -->
    <v-card
      elevation="0"
      class="dense"
      v-if="type === 'CAS' || type === 'CAS_REST_API'"
    >
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>Base_url：</span>
            <span
              :style="{
                color: base_url ? '' : '#9195a3'
              }"
            >
              {{ base_url || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- LDAP || FNU_LDAP -->
    <v-card
      elevation="0"
      class="dense"
      v-if="type === 'LDAP' || type === 'FNU_LDAP'"
    >
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>解码方式：</span>
            <span
              :style="{
                color: encoding ? '' : '#9195a3'
              }"
            >
              {{ encoding || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>服务地址：</span>
            <span
              :style="{
                color: host ? '' : '#9195a3'
              }"
            >
              {{ host || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>端口号：</span>
            <span
              :style="{
                color: port ? '' : '#9195a3'
              }"
            >
              {{ port || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>启用SSL：</span>
            <span
              :style="{
                color: enable_ssl ? '' : '#9195a3'
              }"
            >
              {{ enable_ssl ? "启用" : "关闭" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>用户名：</span>
            <span
              :style="{
                color: username ? '' : '#9195a3'
              }"
            >
              {{ username || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>密码：</span>
            <span
              :style="{
                color: password ? '' : '#9195a3'
              }"
            >
              {{ password || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>Base_DN：</span>
            <span
              :style="{
                color: base_dn ? '' : '#9195a3'
              }"
            >
              {{ base_dn || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>过滤条件：</span>
            <span
              :style="{
                color: search_filter ? '' : '#9195a3'
              }"
            >
              {{ search_filter || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- NACTA_AUTH -->
    <v-card elevation="0" class="dense" v-if="type === 'NACTA_AUTH'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>basic_auth_url：</span>
            <span
              :style="{
                color: basic_auth_url ? '' : '#9195a3'
              }"
            >
              {{ basic_auth_url || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>send_sms_url：</span>
            <span
              :style="{
                color: send_sms_url ? '' : '#9195a3'
              }"
            >
              {{ send_sms_url || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- S002JWT -->
    <v-card elevation="0" class="dense" v-if="type === 'S002JWT'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>audience：</span>
            <span
              :style="{
                color: audience ? '' : '#9195a3'
              }"
            >
              {{ audience || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>signing_algorithm：</span>
            <span
              :style="{
                color: signing_algorithm ? '' : '#9195a3'
              }"
            >
              {{ signing_algorithm || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>verifying_key：</span>
            <span
              :style="{
                color: verifying_key ? '' : '#9195a3'
              }"
            >
              {{ verifying_key || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- DING_TALK -->
    <v-card elevation="0" class="dense" v-if="type === 'DING_TALK'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <span>App Key：</span>
            <span
              :style="{
                color: app_key ? '' : '#9195a3'
              }"
            >
              {{ app_key || "未填写" }}
            </span>
          </v-col>
          <v-col cols="8">
            <span>App Secret：</span>
            <span
              :style="{
                color: app_secret ? '' : '#9195a3'
              }"
            >
              {{ app_secret || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- SMS -->
    <v-card elevation="0" class="dense" v-if="type === 'SMS'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>用户名：</span>
            <span
              :style="{
                color: username ? '' : '#9195a3'
              }"
            >
              {{ username || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>密码：</span>
            <span
              :style="{
                color: password ? '' : '#9195a3'
              }"
            >
              {{ password || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>系统提示信息：</span>
            <span
              :style="{
                color: sms_message ? '' : '#9195a3'
              }"
            >
              {{ sms_message || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- 飞书 -->
    <v-card elevation="0" class="dense" v-if="type === 'LARK'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>App ID：</span>
            <span
              :style="{
                color: app_id ? '' : '#9195a3'
              }"
            >
              {{ app_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>App Secret：</span>
            <span
              :style="{
                color: app_secret ? '' : '#9195a3'
              }"
            >
              {{ app_secret || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- WEBVPN -->
    <v-card elevation="0" class="dense" v-if="type === 'WEBVPN'">
      <v-card-title>认证配置 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>身份源ID：</span>
            <span
              :style="{
                color: external_id ? '' : '#9195a3'
              }"
            >
              {{ external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>显示名称：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>认证地址：</span>
            <span
              :style="{
                color: webvpn_auth_url ? '' : '#9195a3'
              }"
            >
              {{ webvpn_auth_url || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      elevation="0"
      class="dense"
      v-if="
        !(
          type === 'CAS' ||
          type === 'CAS_REST_API' ||
          type === 'LDAP' ||
          type === 'FNU_LDAP' ||
          type === 'OAUTH2' ||
          type === 'OAUTH2_EMBED' ||
          type === 'HTTP_BASIC'
        )
      "
    >
      <v-card-title
        >组织同步选项 <a @click="editDepartment">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <span>根组织：</span>
            <span
              :style="{
                color: departmentConfig.default_department_name ? '' : '#9195a3'
              }"
            >
              {{ departmentConfig.root_department_name || "未填写" }}
            </span>
          </v-col>
          <v-col
            ><span>合并根组织：</span>
            {{
              departmentConfig.merge_root_department === "merge" ? "是" : "否"
            }}</v-col
          >
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col
            ><span>创建组织：</span>
            {{
              departmentConfig.create_entity === "create" ? "是" : "否"
            }}</v-col
          >
          <v-col
            ><span>更新组织：</span>
            {{
              departmentConfig.update_entity === "update" ? "是" : "否"
            }}</v-col
          >
          <v-col
            ><span>过期组织：</span>
            {{
              departmentConfig.stale_entity === "keep" ? "保留" : "禁用"
            }}</v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense">
      <v-card-title
        >账户同步选项 <a @click="editAccount">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <span>匹配规则： </span>
            <v-chip
              color="primary"
              outlined
              class="ma-1"
              v-for="(item, index) in accountConfig.matchers"
              :key="`${item.claims}_${index}`"
            >
              <v-icon>mdi-swap-horizontal</v-icon>&nbsp;
              {{ match_types[item.matcher] || item.matcher }}
            </v-chip>
          </v-col>
          <v-col><span>白名单规则：</span> 默认全部放行</v-col>
          <v-col
            v-if="
              !(
                type === 'LDAP' ||
                type === 'WECHAT_ENTERPRISE' ||
                type === 'DING_TALK' ||
                type === 'LARK'
              )
            "
          >
            <span>根组织：</span>
            <span
              :style="{
                color: departmentConfig.default_department_name ? '' : '#9195a3'
              }"
            >
              {{ departmentConfig.default_department_name || "未填写" }}
            </span>
          </v-col>
          <v-col v-else></v-col>
        </v-row>
        <v-row>
          <v-col
            ><span>允许同步新用户：</span>
            {{ accountConfig.create_entity === "create" ? "是" : "否" }}</v-col
          >
          <v-col
            ><span>更新用户属性：</span>
            {{ accountConfig.update_entity === "update" ? "是" : "否" }}</v-col
          >
          <v-col
            ><span>过期用户：</span>
            {{ accountConfig.stale_entity === "keep" ? "保留" : "禁用" }}</v-col
          >
        </v-row>
        <v-row>
          <v-col
            ><span>缺省用户组：</span>
            <span
              :style="{
                color: accountConfig.default_group_name ? '' : '#9195a3'
              }"
            >
              {{ accountConfig.default_group_name || "未填写" }}
            </span>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      type="edit"
      :allDatas="allDatas"
      :departmentTree="departmentTree"
      ref="child"
    />
  </div>
</template>

<script>
import { api_request } from "@/util/network";

import CasEditor from "@/views/source/editor/Cas";
import Oauth2Editor from "@/views/source/editor/Oauth2";
import Oauth2EmbedEditor from "@/views/source/editor/Oauth2Embed";
import LdapEditor from "@/views/source/editor/Ldap";
import FNU_LdapEditor from "@/views/source/editor/FNU_Ldap";
import HttpBasicEditor from "@/views/source/editor/HttpBasic";
import CasRestAPI from "@/views/source/editor/CasRestAPI";
import WechatEnterpriseEditor from "@/views/source/editor/WechatEnterprise";
import AccountConfig from "@/views/source/editor/AccountConfig";
import DepartmentSync from "@/views/source/editor/DepartmentSync";
import DingTalk from "@/views/source/editor/DingTalk";
import LarkEditor from "@/views/source/editor/Lark";
import NactaAuth from "@/views/source/editor/NactaAuth";
import S002Jwt from "@/views/source/editor/S002Jwt";
import Sms from "@/views/source/editor/Sms";
import WebVPN from "@/views/source/editor/WebVPN";

const EDITORS = {
  CAS: CasEditor,
  LDAP: LdapEditor, //同步功能
  FNU_LDAP: FNU_LdapEditor, //同步功能
  OAUTH2: Oauth2Editor,
  OAUTH2_EMBED: Oauth2EmbedEditor,
  HTTP_BASIC: HttpBasicEditor,
  WECHAT_ENTERPRISE: WechatEnterpriseEditor, //同步功能
  CAS_REST_API: CasRestAPI,
  ACCOUNT: AccountConfig,
  DEPARTMENT: DepartmentSync,
  DING_TALK: DingTalk, //同步功能
  LARK: LarkEditor, //同步功能
  NACTA_AUTH: NactaAuth,
  S002JWT: S002Jwt,
  SMS: Sms,
  WEBVPN: WebVPN
};

export default {
  name: "DataSource",
  props: {
    allDatas: {
      type: Object
    }
  },
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      field_namesObj: {
        userid: "账户",
        mobile: "手机号"
      },
      match_types: {
        mobilephone: "手机号匹配",
        external_id: "用户ID匹配"
      },
      departmentTree: [],
      external_id: "",
      name: "",
      corp_id: "",
      agent_secret: "",
      // WECHAT_ENTERPRISE
      agent_id: "",
      wechat_qrcode: false,

      // OAUTH2 OAUTH2_EMBED
      authorization_base_url: "",
      client_id: "",
      client_secret: "",
      identity_attribute: "",
      scope: "",
      token_api_url: "",
      user_api_url: "",
      // CAS
      base_url: "",

      // NACTA_AUTH
      basic_auth_url: "",
      send_sms_url: "",

      // S002JWT
      audience: "",
      signing_algorithm: "",
      verifying_key: "",

      // DING_TALK
      app_key: "",

      // SMS
      username: "",
      password: "",
      sms_message: "",

      // LARK
      app_id: "",
      app_secret: "",

      // LDAP
      base_dn: "",
      enable_ssl: null,
      encoding: "",
      host: "",
      port: null,
      search_filter: "",

      //FNU_LDAP
      sms_auth: {},

      // WEBVPN
      webvpn_auth_url: "",

      type: null,
      accountConfig: {
        create_entity: "",
        stale_entity: "",
        update_entity: "",
        default_group_name: "",
        matchers: []
      },
      departmentConfig: {
        create_entity: "",
        merge_root_department: "",
        root_department_name: null,
        default_department_name: null,
        stale_entity: "",
        update_entity: ""
      },
      matchers: []
    };
  },
  mounted() {
    this.getCurIdData();
    this.getTree();
  },
  methods: {
    getCurIdData() {
      this.type = this.allDatas.type;
      this.departmentConfig.root_department_name = this.allDatas.root_department_name;
      this.departmentConfig.default_department_name = this.allDatas.default_department_name;
      this.external_id = this.allDatas.external_id;
      this.name = this.allDatas.name;
      this.corp_id = this.allDatas.options.corp_id;
      this.agent_secret = this.allDatas.options.agent_secret;
      // WECHAT_ENTERPRISE
      if (this.type === "WECHAT_ENTERPRISE") {
        this.agent_id = this.allDatas.options.agent_id;
        this.agent_secret = this.allDatas.options.agent_secret;
        this.wechat_qrcode = this.allDatas.options.wechat_qrcode;
      }

      //OAUTH2
      if (this.type === "OAUTH2" || this.type === "OAUTH2_EMBED") {
        this.authorization_base_url = this.allDatas.options.authorization_base_url;
        this.client_id = this.allDatas.options.client_id;
        this.client_secret = this.allDatas.options.client_secret;
        this.identity_attribute = this.allDatas.options.identity_attribute;
        this.scope = this.allDatas.options.scope;
        this.token_api_url = this.allDatas.options.token_api_url;
        this.user_api_url = this.allDatas.options.user_api_url;
      }

      // CAS
      this.base_url = this.allDatas.options.base_url;

      // WEBVPN
      this.webvpn_auth_url = this.allDatas.options.webvpn_auth_url;

      // NACTA_AUTH
      if (this.type === "NACTA_AUTH") {
        this.basic_auth_url = this.allDatas.options.basic_auth_url;
        this.send_sms_url = this.allDatas.options.send_sms_url;
      }

      // S002JWT
      if (this.type === "S002JWT") {
        this.audience = this.allDatas.options.audience;
        this.signing_algorithm = this.allDatas.options.signing_algorithm;
        this.verifying_key = this.allDatas.options.verifying_key;
      }

      // DING_TALK
      if (this.type === "DING_TALK") {
        this.app_key = this.allDatas.options.app_key;
        this.app_secret = this.allDatas.options.app_secret;
      }

      // SMS
      if (this.type === "SMS") {
        this.password = this.allDatas.options.sms_auth.password;
        this.username = this.allDatas.options.sms_auth.username;
        this.sms_message = this.allDatas.options.sms_message;
      }

      // LARK
      if (this.type === "LARK") {
        this.app_id = this.allDatas.options.app_id;
        this.app_secret = this.allDatas.options.app_secret;
      }

      // LDAP
      if (this.type === "LDAP" || this.type === "FNU_LDAP") {
        let options = this.allDatas.options;
        if (options) {
          let {
            base_dn,
            enable_ssl,
            encoding,
            host,
            password,
            port,
            search_filter,
            username,
            sms_auth
          } = options;
          this.base_dn = base_dn;
          this.enable_ssl = enable_ssl;
          this.encoding = encoding;
          this.host = host;
          this.password = password;
          this.port = port;
          this.search_filter = search_filter;
          this.username = username;
          if (sms_auth) {
            this.sms_auth = sms_auth;
          }
        }
      }
      let mapping_options = this.allDatas.mapping_options;
      if (
        mapping_options &&
        mapping_options.account &&
        mapping_options.account.length > 0
      ) {
        let accountStrategy = mapping_options.account[0].strategy;

        this.accountConfig.create_entity = accountStrategy.create_entity;
        this.accountConfig.stale_entity = accountStrategy.stale_entity;
        this.accountConfig.update_entity = accountStrategy.update_entity;
        this.accountConfig.default_group_name =
          accountStrategy.default_group_name;
        this.accountConfig.matchers = mapping_options.account[0].matchers;
      }

      if (
        mapping_options &&
        mapping_options.department &&
        mapping_options.department.length > 0
      ) {
        let departmentStrategy = mapping_options.department[0].strategy;

        this.departmentConfig.create_entity = departmentStrategy.create_entity;
        this.departmentConfig.merge_root_department =
          departmentStrategy.merge_root_department;
        this.departmentConfig.stale_entity = departmentStrategy.stale_entity;
        this.departmentConfig.update_entity = departmentStrategy.update_entity;
      }
    },
    getTree() {
      this.$http
        .get("api/department/tree")
        .delegateTo(api_request)
        .then(data => {
          if (data.departments.length > 0) {
            this.departmentTree = data.departments.map(item => {
              item.name = item.name === "." ? "企业根目录" : item.name;
              return item;
            });
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    show_sheet() {
      this.sheet_editor = EDITORS[this.type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    editAccount() {
      this.sheet_editor = EDITORS["ACCOUNT"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
      this.$nextTick(() => {
        this.$refs.child.fetchData();
      });
    },
    editDepartment() {
      this.sheet_editor = EDITORS["DEPARTMENT"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  watch: {
    allDatas() {
      this.getCurIdData();
      this.getTree();
    }
  },
  components: {
    CasEditor,
    Oauth2Editor,
    LdapEditor,
    FNU_LdapEditor,
    LarkEditor,
    HttpBasicEditor,
    WechatEnterpriseEditor,
    AccountConfig,
    DepartmentSync,
    DingTalk,
    NactaAuth,
    S002Jwt,
    Sms,
    WebVPN
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
